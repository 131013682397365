"use client";

import { createContext, useContext, useState, useEffect } from 'react';
import useQuery from "@src/hooks/use-query";
import { useCurrentUser } from "@src/hooks/use-current-user";
type ConfigType = {
  footer_code: string;
  header_code: string;
  meta: string;
  customer_keywords: string[];
  total_cart_items: number;
  trending_keywords: string[];
  payment_methods: Array<any>;
  flash_sale: {
    id: number;
    from: string;
    to: string;
  };
};
const defaultCtx = {
  config: null as ConfigType | null,
  user: {},
  refetch: () => {}
};
const LayoutContext = createContext(defaultCtx);
export const LayoutProvider = ({
  children
}) => {
  const {
    data,
    loading,
    refetch
  } = useQuery({
    url: "/config"
  });
  const {
    user
  } = useCurrentUser();
  const [config, setConfig] = useState(null);
  useEffect(() => {
    if (!loading && data) {
      setConfig({
        ...data,
        flash_sale: data.flash_sale ? {
          ...data.flash_sale,
          colorSale: '#D8042C',
          isAvailable: new Date().getTime() > new Date(data.flash_sale.from).getTime() && new Date().getTime() < new Date(data.flash_sale.to).getTime()
        } : null
      });
    }
  }, [data, loading]);
  return <LayoutContext.Provider value={{
    config,
    user,
    refetch
  }} data-sentry-element="unknown" data-sentry-component="LayoutProvider" data-sentry-source-file="provider.tsx">
      {children}
    </LayoutContext.Provider>;
};
export function useLayoutContext() {
  return useContext(LayoutContext);
}